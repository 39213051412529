@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Signika:wght@300;400;500;600;700&display=swap');
body {
  margin: 0;
  padding:0;
  box-sizing: border-box;
  font-size: medium;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}
*{
  font-family: 'Abril Fatface', cursive;
font-family: 'Signika', sans-serif;
}

a{
  text-decoration: none !important;
  color:#000 !important;


}

a:hover{
  color:#000 !important;
}

.fade-anim img {
  animation: fadeAnim 0.5s ease-out;
}

@keyframes fadeAnim {
  from {
    opacity: 0;
  }
  to {
    opacity: 1
  }
}

@keyframes fadeAnimOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0
  }
}

.premium-fade-in {
  animation: fadeAnim 1s ease-in;
}

.premium-fade-out {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}